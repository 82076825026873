(function () {
  'use strict';

  let $registo = $('#registo form'),
    $infield = $('.infield'),
    $mgalscroll = $('.mgallery.scroll'),
    $mgalimg = $mgalscroll.find('.mgal-img a'),
    $mgalaud = $mgalscroll.find('.mgal-aud .audioplayer'),
    $slbnscroll = $('.slide-banner.scroll'),
    $login = $('#login'),
    $validate = $('.validate'),
    $e,
    controlSettings = {
      fullscreen: false,
      height: 25,
      autoHide: false,
      'timeColor': 'rgba(133, 133, 133, 1)',
      //'borderRadius':30,
      'bufferGradient': 'none',
      'backgroundColor': 'rgba(255, 255, 255, 1)',
      'volumeSliderGradient': 'none',
      'timeBorderRadius': 20,
      'time': true,
      'progressGradient': 'none',
      'volumeColor': 'rgba(255, 101, 18, 1)',
      'tooltips': {'marginBottom': 5, 'buttons': false},
      'opacity': 1,
      'timeFontSize': 11,
      'volumeSliderColor': 'rgba(0, 0, 0, 0)',
      'bufferColor': 'rgba(255, 101, 18, 1)',
      'border': '0',
      'buttonColor': 'rgba(255, 101, 18, 1)',
      'backgroundGradient': 'none',
      'width': 230,
      //'left':'50pct',
      'display': 'block',
      'sliderBorder': '0',
      'buttonOverColor': 'rgba(189, 189, 189, 1)',
      //'url':'flowplayer.controls-3.2.5.swf',
      'timeBgColor': 'rgb(0, 0, 0, 0)',
      'borderWidth': 0,
      'scrubberBarHeightRatio': 0.2,
      'bottom': 0,
      'buttonOffColor': 'rgba(130,130,130,1)',
      'zIndex': 1,
      'sliderColor': 'rgba(209, 209, 209, 1)',
      'scrubberHeightRatio': 0.3,
      'tooltipTextColor': 'rgba(0, 0, 0, 0)',
      'sliderGradient': 'none',
      'spacing': {'time': 0, 'volume': 8, 'all': 2},
      'timeBgHeightRatio': 0.8,
      'volumeSliderHeightRatio': 0.6,
      //'name':'controls',
      'timeSeparator': ' ',
      'volumeBarHeightRatio': 0.2,
      'tooltipColor': 'rgba(0, 0, 0, 0)',
      'durationColor': 'rgba(255, 101, 18, 1)',
      'timeBorder': '0px solid rgba(0, 0, 0, 0.3)',
      'progressColor': 'rgba(255, 101, 18, 1)',
      'volume': false,
      'scrubber': true,
      'builtIn': false,
      mute: false,
      'volumeBorder': '1px solid rgba(128, 128, 128, 0.7)'
      //'margins':[2,12,2,12]
    };


  $('.mainlisting.autolink tbody tr').click(function () {
    var redir = $(this).find('a').attr('href') || $(this).attr('data-href');
    if (redir)
      window.location = redir;
  });

  $('#servicoslist tbody tr').click(function (e) {
    var a = $(this).find('a');
    if (a.length && confirm($(this).parents('table:eq(0)').attr('data-confirmq'))) {
      window.location = a.attr('href');
    }
    e.preventDefault();
  });

  $('.mainlisting tr:not(.nohover)').addClass('hoverspecial');

  $('#recinfeedback li').each(function () {
    $(this).find('input[type=radio]').click(function () {
      var $target = $(this).parents('li:eq(0)').find('.hide');
      !$(this).val() && $target.show() || $target.hide();
    }).eq(0).click();
  });
  if (($e = $('#qcp')) && $e.length) {
    (function ($el) {
      var cm = $('#casa_moeda').hide(),
        ca = $('#completo_aprovado').hide(),
        vd = $('#validou_dados').hide();
      $el.find(':radio').click(function () {
        var $this = $(this),
          name = $this.attr('name'),
          value = $this.val();
        if (name === 'recebeu_cedula') {
          !value && cm.show().find(':radio:checked').click() || cm.hide() && ca.hide() && vd.hide();
        }
        else if (name === 'recebeu_formulario') {
          ca.hide();
          vd.hide();
          !value && ca.show() || vd.show();
        }
      });

    })($e);
  }
  if (($e = $('.showhide')) && $e.length) {
    $('.maisinfo').hide();
    $e.find('.underline').click(function (e) {
      var next = $(this).next();
      if (next.is(':hidden')) {
        next.show();
        $(this).text($(this).attr('data-lfechar'));
      } else {
        next.hide();
        $(this).text($(this).attr('data-labrir'));
      }
      e.preventDefault();
    });
  }

  $('.addthis').append('<div class="addthis_toolbox addthis_default_style"><a class="addthis_button_facebook" fb:like:layout="button_count"></a><a class="addthis_button_twitter"><a class="addthis_button_google_plusone" g:plusone:count="false" g:plusone:annotation="none"></a><a class="addthis_button_compact"></a></div>');
  if (($e = $('.addthisurl')) && $e.length) {
    (function ($e) {
      var url, title, $el;
      $e.each(function (i) {
        $el = $e.eq(i);
        url = document.location.protocol + '//' + document.location.hostname + $el.data('addurl');
        title = $el.data('addtitle');
        $el.append('<div class="addthis_toolbox addthis_default_style" addthis:url="' + url + '" addthis:title="' + title + '"><a class="addthis_button_facebook" fb:like:layout="button_count"></a><a class="addthis_button_twitter"><a class="addthis_button_google_plusone" g:plusone:count="false" g:plusone:annotation="none"></a><a class="addthis_button_compact"></a></div>');
      });
    })($e);
  }
  addthis.init();

  if ($mgalscroll.length) {
    $mgalscroll.addClass('scrollable')
      .find('.items').addClass('scrollable');

    $mgalscroll.find('.mitemlist ul').each(function () {
      var width = 0;
      $(this).find('li').each(function () {
        width += $(this).outerWidth(true);
      });
      $(this).css('width', width);
    });

    $mgalscroll.find('nav ul').addClass('navi');
    $mgalscroll.scrollable().navigator({activeClass: 'sel', naviItem: 'a'});
  }

  if ($slbnscroll.length && $slbnscroll.find('.items *').length) {
    $slbnscroll.addClass('scrollable')
      .find('.items').addClass('scrollable');

    $slbnscroll.find('.nav').addClass('navi');
    $slbnscroll.scrollable({circular: true})
      .autoscroll({autoplay: true, interval: 7000})
      .navigator({activeClass: 'sel', naviItem: 'a'});
  }
  $('.calendar a').click(function () {
    var $art = $('.diary article');
    $art.find('time').removeClass('sel');
    $art.filter('.d_' + $(this).html()).find('.pubtime').addClass('sel');
    //$('.page article').removeClass('sel').filter('.day_'+$(this).html()).addClass('sel');
  });

// confirm submit
  $('.confirm').submit(function (e) {
    if (!confirm($(this).data('confmessage') || 'Tem a certeza que quer submeter?'))
      e.preventDefault();
  });

  if ($.tools && $.tools.validator) {

    $.tools.validator.addEffect('wall', function (errors) {

      // elemento container
      var container = this.getConf().container,
        wall = $(container).fadeIn();

      // saltar para div erros
      $(document).scrollTop($(container).parent().offset().top);

      // eliminar mensagens existentes
      wall.find('p').remove();

      // adicionar erros
      $.each(errors, function (index, error) {
        wall.append(
          '<p><strong>' + error.input.attr('title') + '</strong> - ' + error.messages[0] + '</p>'
        );
      });

      // valido
    }, function () {
      $(this.getConf().container).fadeOut();
    });

    $.tools.validator.localize('pt', {
      '*': 'Por favor, corrija este valor',
      ':email': 'Por favor, insira um endereço de email válido',
      ':number': 'Por favor, insira um valor numérico',
      ':url': 'Por favor, insira um endereço válido',
      '[max]': 'Por favor, insira um número menor do que $1',
      '[min]': 'Por favor, insira um número maior do $1',
      '[required]': 'Por favor, preencha este campo obrigatório'
    });

    $.tools.validator.conf.effect = 'wall';
    $.tools.validator.conf.errorInputEvent = null;
    $.tools.validator.conf.lang = 'pt';

    $validate.length && $validate.validator({container: '#errors'});
    $login.length && $login.validator({container: '#errors'});
  }


  $('.showHideRadio').click(function () {
    if ($(this).data('show-hide-action') === 'show') {
      $('.' + $(this).data('show-hide-target')).show();
    }
    else {
      $('.' + $(this).data('show-hide-target')).hide();
    }
  });

  yepnope([
    {
      test: $('[data-read-only]').length,
      yep: '/assets/js/readonly.js',
      callback: {
        'readonly.js': function () {
          $('[data-read-only]').readOnly();
        }
      }
    },
    {
      test: $registo.length,
      yep: [
        '/assets/js/jquery.sheepItPlugin.js',
        '/assets/js/checkboxlimiter.min.js',
        '/assets/js/registo.js'
      ]
    },
    {
      test: $('.checkboxlimiter').length,
      yep: ['/assets/js/checkboxlimiter.min.js']
    },
    {
      test: $infield.length,
      yep: '/assets/js/inlabel.js',
      complete: function () {
        $infield.css('position', 'relative').find('label').css('position', 'absolute').inFieldLabels();
      }
    },
    {
      test: $mgalscroll.length || $('.podcastplayer').length,
      yep: '/assets/js/scrolldep.js',
      callback: {
        'scrolldep.js': function () {
          if ($('.podcastplayer').length) {
            $('.podcastplayer').html('');
            $f('.podcastplayer', {
              src: '/assets/js/flowplayer-3.2.6.swf',
              wmode: 'transparent'
            }, {
              plugins: {
                controls: $.extend({left: 0}, controlSettings)
              },
              clip: {autoPlay: false}
            });
          }

          $mgalscroll.find('.mitemlist').jScrollPane({showArrows: true});

          if ($mgalaud.length) {
            $('.audioplayer').click(function (e) {
              e.preventDefault();
              $(this).html('').unbind('click');
              $f(e.target, {
                src: '/assets/js/flowplayer-3.2.6.swf',
                wmode: 'opaque'
              }, {
                plugins: {
                  controls: controlSettings
                },
                clip: {
                  autoPlay: true
                }
              });
            });
          }

          if ($mgalimg.length)
            $mgalimg.fancybox({
              transitionIn: 'elastic',
              overlayShow: false,
              titlePosition: 'over',
              centerOnScroll: true
            });
        }
      }
    },
    {
      test: $('.changeshowhide').length,
      yep: [
        '/assets/js/jquery.change-show-hide.js'
      ],
      callback: {
        'jquery.change-show-hide.js': function () {
          $('.changeshowhide').changeshowhide({
            afterDisable: function (obj, $target) {
              $target.find(':input').attr('disabled', true);
            },
            afterEnable: function (obj, $target) {
              $target.find(':input:visible').attr('disabled', false);
            }
          });
        }
      }
    },
    {
      test: $('.prevencaoCalculator').length,
      yep: [
        '/assets/js/prevencao-calculator.js'
      ],
      callback: {
        'prevencao-calculator.js': function () {
          $('.prevencaoCalculator').prevencaoCalculator();
        }
      }
    },
    {
      test: !!document.getElementById('jquery_jplayer_1'),
      yep: [
        '/assets/js/jplayer.2.2.0/skin/blue.monday/jplayer.blue.monday.css',
        '/assets/js/jplayer.2.2.0/jquery.jplayer.min.js'
      ],
      callback: {
        'jquery.jplayer.min.js': function () {

          var player = $('#jquery_jplayer_1').jPlayer({
            ready: function () {
              $(this).jPlayer('setMedia', {
                mp3: $('.jp-track').eq(0).attr('href')
              });
            },
            swfPath: '/assets/js/jplayer.2.2.0',
            supplied: 'mp3',
            wmode: 'window',
            solution: 'html,flash'
          });

          $('.podcastplaylist .jp-track').click(function (e) {

            player.jPlayer('setMedia', {
              mp3: $(this).attr('href')
            }).jPlayer('play');

            e.preventDefault();
          });
        }
      }
    }
  ]);

})();